import React from "react";

const Loading = () => {
  const loaderStyle = {
    width: "3rem",
    height: "3rem",
    border: `0.3em solid #4FAF5A`,
    borderTop: `0.3em solid transparent`,
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  };

  const wrapperStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: 9999,
  };

  return (
    <div style={wrapperStyle}>
      <div style={loaderStyle}></div>
    </div>
  );
};

export default Loading;
