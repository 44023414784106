import { Button } from "react-bootstrap";
import "../../components/poupup/popup.css";
import successImg from "../../assets/images/order-done.svg";
const SuccessPayment = () => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <img src={successImg} alt="order-done" />
        <h3>تم الدفع بنجاح</h3>

        <div className="poupop-group-btns">
          <Button
            className="default-btn"
            style={{ width: " 80%" }}
            onClick={() => window.open("https://dev.feastap.com/", "_blank")}
          >
            رجوع
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SuccessPayment;
