import { Button } from "react-bootstrap";
import "../../components/poupup/popup.css";
import failedImg from "../../assets/images/failed.svg";
import { useNavigate } from "react-router-dom";
const FailedPayment = () => {
  const navigate = useNavigate();
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <img src={failedImg} alt="order-done" />
        <h3>فشل في الدفع</h3>

        <div className="poupop-group-btns">
          <Button
            className="default-btn"
            style={{ width: " 80%" }}
            onClick={() => navigate("/order", "_blank")}
          >
            رجوع
          </Button>
        </div>
      </div>
    </div>
  );
};
export default FailedPayment;
