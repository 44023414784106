import { Minus, Plus } from "lucide-react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
const SplitPaymentOrder = ({
  product,
  handleCheckboxChange,
  updateTotalPrice,
}) => {
  const [quantity, setQuantity] = useState(product.remainQuantity);

  const handleDecrementQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      const oldPrice = product.summary.price_with_tax_and_discount * quantity;
      const newPrice =
        product.summary.price_with_tax_and_discount * newQuantity;

      setQuantity(newQuantity);
      updateTotalPrice(
        product.size.cart_item_id,
        oldPrice,
        newPrice,
        newQuantity // Pass the new quantity
      );
    }
  };

  const handleIncrement = () => {
    if (quantity >= product.remainQuantity) {
      toast.error("لايمكن زيادة الكمية أكثر من الموجودة في طلبك", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    } else {
      const newQuantity = quantity + 1;
      const oldPrice = product.summary.price_with_tax_and_discount * quantity;
      const newPrice =
        product.summary.price_with_tax_and_discount * newQuantity;

      setQuantity(newQuantity);
      updateTotalPrice(
        product.size.cart_item_id,
        oldPrice,
        newPrice,
        newQuantity // Pass the new quantity
      );
    }
  };

  return (
    <>
      {" "}
      <div className="split-order-item">
        <div className="split-order-details">
          <img src={product.image} alt={product.name} />
          <div className="content">
            <h4>
              {product.name} <span>{product.size.name}</span>
            </h4>
            <p>{product.summary.price_with_tax_and_discount} ر.س</p>
            <div className="btns-container">
              <Button onClick={handleIncrement}>
                <Plus size={"15"} />
              </Button>
              <div className="quantity">{quantity}</div>
              <Button>
                <Minus
                  size={"15"}
                  onClick={handleDecrementQuantity}
                  disabled={quantity <= 1}
                />
              </Button>
            </div>
          </div>
        </div>

        <Form.Check
          className="custom-checkbox"
          type="checkbox"
          reverse
          onChange={(e) =>
            handleCheckboxChange(
              e,
              product.size.cart_item_id,
              quantity,
              product.summary.price_with_tax_and_discount
            )
          }
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default SplitPaymentOrder;
