import { useEffect, useState } from "react";
import "./cart.css";
import { Button } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import Loading from "../../components/loading/loading";
import ProductCartItem from "./ProductCartItem";
import emptyCart from "../../assets/images/empty-cart.svg";
import { axiosTokenInstance } from "../../axiosConfig/axios";

import cartLocation from "../../assets/images/cart-location.svg";
import arrow from "../../assets/images/arrow.svg";
import Note from "../../components/note/note";
import circleAdd from "../../assets/images/product-details/Group 1000001487.svg";

const Card = () => {
  const navigate = useNavigate();
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cartProductDetails, setCartProductDetails] = useState([]);
  const [restaurantDetails, setRestaurantDetails] = useState({});
  const [total_price_with_taxs, setTotal_price_with_taxs] = useState(null);
  let [quantity, setQuantity] = useState({});
  let [totalPrice, setTotalPrice] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control popup visibility

  // get cart product details
  const getCartDetails = () => {
    axiosTokenInstance.get("user/cart").then((res) => {
      // console.log(res.data.data);

      if (res.data.data) {
        setTotalPrice(res.data.data.total_price_with_taxs);

        setCartProductDetails(res.data.data.products);
        setRestaurantDetails(res.data.data.restaurant);
        setTotal_price_with_taxs(res.data.data.total_price_with_taxs);

        const quantities = {};
        res.data.data.products.forEach((product) => {
          quantities[product.productCartId] = product.quantity;
        });
        setQuantity(quantities);
      } else {
        setCartProductDetails([]);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getCartDetails();
  }, []);

  //delete cart product details
  const emptyProductsCart = () => {
    axiosTokenInstance.post("user/cart/emptyCart", {}).then((res) => {
      if (res.status === 200) {
        toast.success(" تم الحذف بنجاح.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });

        getCartDetails();
      }
    });
  };

  //delete product from cart
  const deleteProduct = (id) => {
    axiosTokenInstance.post(`user/cart/removeItem/${id} `, {}).then((res) => {
      if (res.status === 200) {
        toast.success(" تم الحذف بنجاح.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });

        getCartDetails();
      }
    });
  };

  // Update quantity for specific product
  const handleQuantityChange = (productCartId, newQuantity) => {
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [productCartId]: newQuantity,
    }));
  };

  // prepare data for updating quantity
  const requestUpdateQuantity = Object.entries(quantity).map(
    ([productCartId, qty]) => ({
      productCartId,
      quantity: qty,
    })
  );

  const updateQuantity = () => {
    axiosTokenInstance
      .post(`user/cart/updateQuantityMulti`, {
        items: requestUpdateQuantity,
      })
      .then((res) => {
        // console.log(res);
      });
  };

  useEffect(() => {
    updateQuantity();
  }, [quantity]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!isPopupOpen && (
            <div>
              <div style={{ padding: "0 20px" }}>
                <div>
                  <h2
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "black",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    السلة
                  </h2>
                </div>

                {cartProductDetails?.length > 0 ? (
                  <>
                    {/* Start header about restaurant*/}
                    <div className="ordered-from-restaurant">
                      <h3>تم الطلب من</h3>
                      <hr />
                      <div className="content">
                        <div className="details-container">
                          <img src={cartLocation} alt="cart-location" />
                          <div className="details">
                            <h4>{restaurantDetails.name}</h4>
                            <p>{restaurantDetails.address}</p>
                          </div>
                        </div>
                        <Link to="/menu">
                          <img src={arrow} alt="arrow" />
                        </Link>
                      </div>
                    </div>
                    {/* End header about restaurant  */}

                    {/* Start cart products details */}
                    <div className="product-container">
                      <div className="header">
                        <p>تفاصيل الطلب</p>

                        <div
                          style={{ color: "red" }}
                          onClick={emptyProductsCart}
                        >
                          مسح الكل
                        </div>
                      </div>

                      <hr />

                      {cartProductDetails?.map((product, index) => {
                        return (
                          <ProductCartItem
                            key={product.productCartId}
                            productDetails={product}
                            quantity={quantity[product.productCartId] || 1}
                            setQuantity={(newQty) =>
                              handleQuantityChange(
                                product.productCartId,
                                newQty
                              )
                            }
                            deleteProductFun={() =>
                              deleteProduct(product.productCartId)
                            }
                            getCartDetails={getCartDetails}
                            showDivider={index < cartProductDetails.length - 1}
                          />
                        );
                      })}
                    </div>
                    {/* End cart products details */}

                    {/* Note of products details */}
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        padding: "20px",
                        margin: "20px 0",
                      }}
                    >
                      <Note
                        notes={notes}
                        setNotes={setNotes}
                        circleAddImg={circleAdd}
                        title="أضف ملاحظاتك علي الطلب"
                        text="أضف ملاحظاتك"
                      />
                    </div>
                    {/* End Note of products details */}

                    {/* Start price details */}

                    <div className="cart-price-details">
                      <h6>تفاصيل الفاتورة</h6>
                      <hr />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: "#4FAF5A",
                        }}
                      >
                        <p
                          style={{
                            margin: "0",

                            fontWeight: "bold",
                          }}
                        >
                          الإجمالي الصافي
                        </p>
                        <p style={{ margin: "0" }}>{totalPrice}</p>
                      </div>
                    </div>

                    {/* End  price details */}
                  </>
                ) : (
                  <div className="empty-cart">
                    <div className="content">
                      <img src={emptyCart} alt="empty-cart" />
                      <h3>"سلتك خاوية! متى بتطلب؟"</h3>
                      <p>فى انتظار طلبك</p>
                    </div>
                  </div>
                )}

                <ToastContainer />
              </div>

              {cartProductDetails?.length > 0 && (
                <div className="main-content-price-cart">
                  <div className="cart-group-btns">
                    <Button
                      style={{
                        backgroundColor: "#37BD6B",
                        color: "white",
                        border: "none",
                      }}
                      onClick={() => {
                        localStorage.setItem("notes", notes);
                        navigate("/coupon");
                      }}
                    >
                      تأكيد الطلب
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid rgba(211, 211, 211, 1)",
                      }}
                      onClick={() => navigate("/menu")}
                    >
                      اضافة المزيد
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Card;
