import { useEffect, useState } from "react";
import "../showCart/sowCart.css";
import { Link } from "react-router-dom";
import { axiosTokenInstance } from "../../axiosConfig/axios";

const ShowCart = () => {
  let [totalPrice, setTotalPrice] = useState(null);
  let [cartProductsLength, setCartProductsLength] = useState(null);
  // get cart product details
  const getCartDetails = () => {
    axiosTokenInstance.get("user/cart").then((res) => {
      if (res.data.data) {
        setTotalPrice(res.data.data.total_price_with_taxs);

        setCartProductsLength(res.data.data.products.length);
      }
    });
  };

  useEffect(() => {
    getCartDetails();
  }, []);

  return (
    <>
      {totalPrice && cartProductsLength && (
        <div className="main-content-cart">
          <div className="showCart">
            <Link to="/cart" className="link">
              <div className="main-content">
                <div className="content">
                  <p>
                    <span>{cartProductsLength ? cartProductsLength : 0}</span>{" "}
                    مشاهدة السلة
                  </p>
                </div>
                <p className="price">
                  {cartProductsLength ? totalPrice : 0} ر.س
                </p>
              </div>
            </Link>
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default ShowCart;
