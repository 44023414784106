import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";
import "./popup.css";
export const Popup = ({
  imgPoupoup,
  title,
  textBtn,
  href,
  closePopup,
  textBtn1,
  href1,
}) => {
  const navigate = useNavigate();
  return (
    <div className="popup-overlay">
      <div onClick={closePopup} className="close">
        <X />
      </div>
      <div className="popup-content">
        <img src={imgPoupoup} alt="order-done" />
        <h3>{title}</h3>

        <div className="poupop-group-btns">
          <Button className="default-btn" onClick={() => navigate(`${href}`)}>
            {textBtn}
          </Button>
          {textBtn1 && href1 && (
            <Button className="other-btn" onClick={() => navigate(`${href1}`)}>
              {textBtn1}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
