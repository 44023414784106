import React, { useState } from "react";
import google from "../../assets/images/google.png";
import apple from "../../assets/images/apple.png";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import axiosInstance from "../../axiosConfig/axios";

function prettyParamText(text) {
  return parseInt(text);
}

const Downloading = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [restaurantQuery] = useState({
    restaurant_id: searchParams.get("restaurant"),
    table_id: searchParams.get("table"),
  });

  localStorage.setItem("table", prettyParamText(searchParams.get("table")));
  localStorage.setItem(
    "restaurant",
    prettyParamText(searchParams.get("restaurant"))
  );

  const [restaurantData, setRestaurantData] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    axiosInstance
      .post("user/scan-qr-code", restaurantQuery, {
        signal: controller.signal,
      })
      .then(function (res) {
        setRestaurantData(res.data.data);
        setIsLoading(false);
      })
      .catch(function (err) {
        console.log(err.code);

        if (err.code !== "ERR_CANCELED") {
          toast.error(err.response?.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        }
      });
    return () => {
      controller.abort();
    };
  }, [isLoading]);

  return (
    <div className="downloading-container">
      <div className="downloading-content">
        <h1>حمل التطبيق واستمتع بمميزاتنا</h1>
        <p>
          هناك حقيقة مثبتة منذ زمن طويل وهى أن المحتوى المقورء لصفحة ما سيلهي
          القارىء عن التركيز على الشكل الخاجى للنص
        </p>

        <div className="row" style={{ display: "block" }}>
          <Link
            to={
              "https://play.google.com/store/apps/details?id=com.safwatech.feastap"
            }
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="storeImg"
              alt="Get it on Google Play"
              style={{ height: "42px", width: "120px" }}
              src={google}
            />
          </Link>
          <Link to={"#"}>
            <img
              src={apple}
              style={{
                height: "40px",
                width: "120px",
                marginRight: "15px",
              }}
              className="storeImg"
              alt="Get it on apple stor"
            />
          </Link>
        </div>

        <Link
          className={`btn ${!restaurantData ? "disabled" : ""}`}
          to={`/menu`}
          state={{ data: restaurantData }}
        >
          {restaurantData ? " تخطي واذهب الي المنيو" : "جاري تحميل المنيو"}
        </Link>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Downloading;
