import recepit from "../../assets/images/product-details/receipt-edit.svg";

import "./note.css";

const Note = ({ notes, setNotes, circleAddImg, title, text }) => {
  return (
    <>
      {/* Start Note of products details */}
      <div className="notes">
        <div className="icon-note-container">
          <div className="icon-container">
            <img src={recepit} alt="recepit" />
            <h3>{title}</h3>
          </div>
          {circleAddImg && (
            <img src={circleAddImg} alt="circleAdd" className="circleAdd" />
          )}
        </div>
        <textarea
          placeholder={text}
          className={circleAddImg ? "FloatingLabel1" : "FloatingLabel"}
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
      </div>
      {/* End Note of products details */}
    </>
  );
};
export default Note;
