import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { axiosTokenInstance } from "../../axiosConfig/axios";
import calendarImg from "../../assets/images/calendar.svg";
import "./order.css";
import OrderDetails from "./order-details";
import OrderStatus from "./order-status";
import Loading from "../../components/loading/loading";

const Order = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [orderProductDetails, setOrderProductDetails] = useState({});

  const order_id = localStorage.getItem("order-id");

  // get order product details
  const getOrderDetails = () => {
    axiosTokenInstance.get(`user/order/${order_id}`).then((res) => {
      console.log(res.data.data);
      setOrderProductDetails(res.data.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format date and time in 12-hour format with "AM/PM"
    const formattedDate = date
      .toLocaleString("en-GB", {
        timeZone: "UTC",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // Use 12-hour format
      })
      .replace(",", "") // Remove any comma
      .replace(/\//g, "-"); // Replace / with -

    // Remove "AM" or "PM" from the string
    return formattedDate.replace(/AM|PM/gi, "").trim();
  };

  // Example usage
  const date = orderProductDetails?.created_at;

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="my-orders">
          <h2>تفاصيل الطلب</h2>
          {/* start section of order details */}
          <div className="order-details-info">
            {orderProductDetails?.info ? (
              <div className="info">
                <img
                  src={orderProductDetails.info.Logo}
                  alt={orderProductDetails.info.resturantName}
                />
                <div className="content">
                  <h3>{orderProductDetails.info.resturantName}</h3>
                  <div className="calendar">
                    <img src={calendarImg} alt="calendar" />
                    <p>{formatDate(date)}</p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <hr />
            <div className="order-details">
              {orderProductDetails?.products?.length > 0 ? (
                orderProductDetails.products.map((product, index) => (
                  <OrderDetails
                    key={index}
                    product={product}
                    quantity={product.quantity}
                  />
                ))
              ) : (
                <></>
              )}
            </div>

            <hr />
            {orderProductDetails?.summary?.discount !== "0.00" ? (
              <div>
                <div className="other-total-price" style={{ color: "black" }}>
                  <h4>الاجمالى </h4>
                  <p>{orderProductDetails?.summary?.total} ر.س</p>
                </div>
                <div className="other-total-price">
                  <h4>الخصم</h4>
                  <p>{orderProductDetails?.summary?.discount} ر.س</p>
                </div>
                <hr />
                <div className="total-price">
                  <h4>الاجمالى الصافى</h4>
                  <p>
                    {orderProductDetails?.summary?.price_with_tax_and_discount}{" "}
                    ر.س
                  </p>
                </div>
              </div>
            ) : (
              <div className="total-price">
                <h4>الاجمالى الصافى</h4>
                <p>
                  {orderProductDetails?.summary?.price_with_tax_and_discount}{" "}
                  ر.س
                </p>
              </div>
            )}
          </div>
          {/* end section of order details */}

          {/* start section of orders not paid*/}
          {orderProductDetails?.paidStatus === "SIMI_PAID" && (
            <div className="order-notPaid">
              <h3>طلبات غير مدفوعة</h3>
              <hr />
              <div className="order-details">
                {orderProductDetails?.remainProducts?.length > 0 ? (
                  orderProductDetails.remainProducts.map((product, index) => (
                    <OrderDetails
                      key={index}
                      product={product}
                      quantity={product.remainQuantity}
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>

              <hr />
              <div className="total-price">
                <h4>الاجمالى الصافى</h4>
                <p>
                  {
                    orderProductDetails?.remainSummary
                      ?.price_with_tax_and_discount
                  }{" "}
                  ر.س
                </p>
              </div>
            </div>
          )}
          {/* end section of orders not paid*/}

          {/* start section of order status*/}
          <OrderStatus
            currentStatus={orderProductDetails?.currentStatus}
            paidStatus={orderProductDetails?.paidStatus}
          />
          {/* end section of order status*/}

          {/* Start section buttons */}
          <div className="order-group-btns">
            {orderProductDetails?.paidStatus === "FULL_PAID" ? (
              <>
                {orderProductDetails?.hasReview ? (
                  <></>
                ) : (
                  <Button
                    className="review"
                    onClick={() => navigate("/review")}
                  >
                    قيم تجربتك
                  </Button>
                )}
              </>
            ) : orderProductDetails?.paidStatus === "SIMI_PAID" ? (
              <>
                <Button
                  className="payment"
                  onClick={() =>
                    orderProductDetails?.paidStatus === "SIMI_PAID"
                      ? navigate("/split-order")
                      : navigate("/waysOfPayment")
                  }
                >
                  دفع
                </Button>
                {orderProductDetails?.hasReview ? (
                  <></>
                ) : (
                  <Button
                    className="review"
                    onClick={() => navigate("/review")}
                  >
                    قيم تجربتك
                  </Button>
                )}
              </>
            ) : (
              <>
                {orderProductDetails?.currentStatus?.name === "Pending" ? (
                  <></>
                ) : (
                  <>
                    <Button
                      className="payment"
                      onClick={() =>
                        orderProductDetails?.paidStatus === "SIMI_PAID"
                          ? navigate("/split-order")
                          : navigate("/waysOfPayment")
                      }
                    >
                      دفع
                    </Button>
                    {orderProductDetails?.hasReview ? (
                      <></>
                    ) : (
                      <Button
                        className="review"
                        onClick={() => navigate("/review")}
                      >
                        قيم تجربتك
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {/* End section buttons */}
        </div>
      )}
    </>
  );
};

export default Order;
