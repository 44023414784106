import React from "react";
import StarImg from "../../assets/images/star.svg";
import HalfStarImg from "../../assets/images/HalfStar.svg";
import badReview from "../../assets/images/bad-review 1.svg";
import GrayStarImg from "../../assets/images/GrayStar.svg";
import Line from "../../assets/images/Line 7.svg";

const Reviews = ({ reviews }) => {
  const avgRating = reviews?.summary?.avg
    ? Number(reviews.summary.avg.toString().slice(0, 4))
    : 0;
  const integerPart = Math.floor(avgRating);
  const hasHalfStar = avgRating % 1 !== 0;

  return (
    <div className="container-product">
      {reviews?.summary || reviews?.reviews.length > 0 ? (
        <div>
          {/* Summary about reviews */}
          <div className="review-summary">
            <div className="content">
              <h2>{avgRating}</h2>
              <div className="stars">
                {Array.from({ length: 5 }).map((_, index) => {
                  if (index < integerPart) {
                    // Full star
                    return <img key={index} width={15} src={StarImg} />;
                  } else if (index === integerPart && hasHalfStar) {
                    // Half star
                    return <img key={index} width={15} src={HalfStarImg} />;
                  } else {
                    // Grey star
                    return <img key={index} width={15} src={GrayStarImg} />;
                  }
                })}
              </div>
              <p className="count">{reviews.summary.count} تقييم</p>
            </div>

            <img src={Line} alt="Line" className="line" />

            <div className="progress-rating">
              {reviews.summary.user_reviews?.map((review, index) => (
                <div key={index} className="progress-rating-item">
                  <div className="rating-label">{review.rating}</div>
                  <div className="progress-bar-container">
                    <div
                      className="progress-bar"
                      style={{ width: `${(review.count / 5) * 100}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* End summary about reviews */}

          <hr />

          {reviews.reviews?.map((review, index) => (
            <div key={index}>
              <div className="review">
                <div className="content">
                  <h3>{review.username}</h3>
                  <p>{review.review}</p>
                </div>
                <div className="rate">
                  <div className="icons">
                    <img src={StarImg} width={15} alt="star" />
                  </div>
                  <div className="num">{review.rate}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-data">
          <img src={badReview} alt="bad-review" />
          <h6>لايوجد مراجعات</h6>
        </div>
      )}
    </div>
  );
};

export default Reviews;
