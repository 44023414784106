const OrderDetails = ({ product, quantity }) => {
  return (
    <div className="order">
      <h4>
        <span> {quantity}</span> {product.name}{" "}
        <span> {product.size.name}</span>
      </h4>
      <p>{product.summary.price_with_tax_and_discount} ر.س</p>
    </div>
  );
};

export default OrderDetails;
