import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { axiosTokenInstance } from "../../axiosConfig/axios";
import SplitPaymentOrder from "./split-payment-order";
import "./payment.css";
import { Button } from "react-bootstrap";
import shareImg from "../../assets/images/Vector.png";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/loading";

const SplitOrder = () => {
  const [orderProductDetails, setOrderProductDetails] = useState({});
  const [selectedTotal, setSelectedTotal] = useState(0);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleCheckboxChange = (e, cart_item_id, quantity, price) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setOptions((prevOptions) => {
        const existingItem = prevOptions.find(
          (opt) => opt.itemId === cart_item_id
        );

        if (existingItem) {
          return prevOptions.map((opt) =>
            opt.itemId === cart_item_id ? { ...opt, qty: quantity } : opt
          );
        }

        return [...prevOptions, { itemId: cart_item_id, qty: quantity }];
      });

      setSelectedTotal((prevTotal) => prevTotal + price * quantity);
    } else {
      setOptions((prevOptions) =>
        prevOptions.filter((option) => option.itemId !== cart_item_id)
      );
      setSelectedTotal((prevTotal) => prevTotal - price * quantity);
    }
  };

  const updateTotalPrice = (cart_item_id, oldPrice, newPrice, newQuantity) => {
    // Check if the item is selected in options
    const isChecked = options.some((option) => option.itemId === cart_item_id);

    if (isChecked) {
      setSelectedTotal((prevTotal) => prevTotal - oldPrice + newPrice);
      setOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.itemId === cart_item_id
            ? { ...option, qty: newQuantity }
            : option
        )
      );
    }
  };

  const order_id = localStorage.getItem("order-id");

  const getRemainProductsDetails = () => {
    axiosTokenInstance.get(`user/order/${order_id}`).then((res) => {
      console.log(res.data);

      setOrderProductDetails(res.data.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getRemainProductsDetails();
  }, []);

  const payPartOfOrder = () => {
    axiosTokenInstance
      .post(`/user/order/simiPay/${order_id}`, {
        items: options,
        payment_from: "web-view",
      })
      .then((res) => {
        if (res.data.redirect_url) {
          // Open the payment window
          window.location.href = res.data?.redirect_url;
        } else {
          console.error("Failed to open payment window.");
        }
      })
      .catch((error) => {
        console.error("Error while initiating payment:", error);
      });
  };

  const sharePayment = () => {
    axiosTokenInstance
      .post(`/user/order/simiPay/${order_id}`, { items: options })
      .then((res) => {
        if (res.data?.redirect_url) {
          const shareUrl = res.data.redirect_url;
          const shareTitle = "مشاركة دفع";
          const shareText = `يرجى الدفع باستخدام هذا الرابط: ${shareUrl}`;

          if (navigator.share) {
            // Use Web Share API
            navigator
              .share({
                title: shareTitle,
                text: shareText,
                url: shareUrl,
              })
              .then(() => {
                console.log("Shared successfully!");
              })
              .catch((error) => {
                console.error("Error sharing:", error);
              });
          } else {
            // Fallback: Copy the link to the clipboard
            navigator.clipboard.writeText(shareUrl).then(() => {
              alert("Link copied to clipboard: " + shareUrl);
            });
          }
        } else {
          console.error("Redirect URL not found!");
        }
      })
      .catch((err) => {
        console.error("Error sharing payment:", err);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="split-my-orders">
            <h2>دفع جزء من الطلب</h2>

            <div className="split-total-price">
              <h4>اجمالى قيمة الطلب</h4>
              <p>
                {orderProductDetails?.summary?.price_with_tax_and_discount} ر.س
              </p>
            </div>

            <div className="order-split-notPaid">
              <h3>اختر العناصر المراد دفعها</h3>
              <hr />
              <div className="order-details">
                {orderProductDetails?.remainProducts?.length > 0 ? (
                  orderProductDetails.remainProducts.map((product, index) => (
                    <React.Fragment key={index}>
                      <SplitPaymentOrder
                        product={product}
                        handleCheckboxChange={handleCheckboxChange}
                        updateTotalPrice={updateTotalPrice}
                      />
                      <hr />
                    </React.Fragment>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="cart-price-details">
              <h6>تفاصيل الفاتورة</h6>
              <hr />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "#4FAF5A",
                  fontWeight: "bold",
                }}
              >
                <p style={{ margin: "0" }}>الإجمالي الصافي</p>
                <p style={{ margin: "0" }}>{selectedTotal} ر.س</p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "12px",
                padding: "18px 20px",
                backgroundColor: "#E3ECF2",
                margin: "10px 0",
              }}
            >
              <p
                style={{
                  margin: "0",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                المبلغ المتبقى
              </p>
              <p style={{ margin: "0", fontWeight: "bold" }}>
                {orderProductDetails?.remainSummary
                  ?.price_with_tax_and_discount - selectedTotal}{" "}
                ر.س
              </p>
            </div>

            <div className="btn-payment">
              <div
                className="share-payment"
                onClick={selectedTotal ? sharePayment : undefined}
              >
                <Button>
                  <img className="Buy" src={shareImg} alt="share" />
                  مشاركة الدفع
                </Button>
              </div>
              <Button onClick={payPartOfOrder} disabled={!selectedTotal}>
                دفع {selectedTotal} ر.س
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SplitOrder;
