import { Form } from "react-bootstrap";
import Dish from "../../assets/images/dish 3.svg";
import Calory from "../../assets/images/huobi-token-(ht).svg";
import Category from "../../assets/images/category-2.svg";
import { Fragment } from "react";

import dollar from "../../assets/images/product-details/dollar-square.svg";
import message from "../../assets/images/product-details/message-add.svg";

import Note from "../../../src/components/note/note";

const ProductDetails = ({
  Product,
  Sizes,
  selectedSize,
  setSelectedSize,
  productPrice,
  setProductPrice,
  productOptions,
  handleCheckboxChange,
  notes,
  setNotes,
  handleSizeChange,
}) => {
  return (
    <div className="meal-product">
      <div className="meal-img">
        <img src={Product.image} alt={Product.name} />
      </div>

      {/* Start Header of Product Details */}
      <div className="meal-content">
        <div className="dish">
          <img src={Dish} alt={Product.name} />
          <h3>{Product.name}</h3>
        </div>

        <div className="meal-container">
          <div className="meal">
            <img src={Calory} alt={Product.name} />
            <div>
              سعر حراري:{" "}
              {Sizes?.map((size, i) => (
                <Fragment key={i}>
                  {size.id === selectedSize ? `${size.calories}  حراري` : ""}
                </Fragment>
              ))}
            </div>
          </div>

          <div className="meal">
            <img src={Category} alt={Product.name} />
            <div>الفئة: {Product.category}</div>
          </div>
        </div>

        <hr />
        <div className="price">
          <p>
            {productPrice} رس{" "}
            <span>
              (
              {Sizes?.map((size, i) => (
                <Fragment key={i}>
                  {size.id === selectedSize ? `${size.name_ar} ` : ""}
                </Fragment>
              ))}
              )
            </span>
          </p>
        </div>
      </div>
      {/* End Header of Product Details */}

      <div className="main-container-details">
        {/* Size of Product Details */}
        <div className="size">
          <div className="icon-container">
            <img src={dollar} alt="dollar" />
            <h3>الأحجام و الأسعار</h3>
          </div>

          <div className="meal-form">
            <Form style={{ padding: "0" }}>
              <div key={"reverse-radio"}>
                {Sizes?.map((Size, index) => (
                  <>
                    {" "}
                    <div key={index} className="priceOfSize">
                      <Form.Check
                        style={{ color: "#838383" }}
                        reverse
                        label={Size.name_ar}
                        name="group1"
                        type="radio"
                        id={`reverse-radio-${index}`}
                        checked={selectedSize === Size.id} // Conditionally check based on state
                        onChange={() =>
                          handleSizeChange(Size.id, Size.price_with_tax)
                        } // Use the handler
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        {Size.price_with_tax <
                          Size.price_before_discount_with_tax &&
                        Size.price_with_tax > 0 ? (
                          <>
                            {/* Discounted Price */}
                            <p
                              style={{
                                color: "red",
                                fontSize: "14px",
                                textDecoration: "line-through",
                                margin: "0",
                              }}
                            >
                              {Size.price_before_discount_with_tax} ر.س{" "}
                              {/* Original Price with a strikethrough */}
                            </p>
                            <p
                              style={{
                                margin: "0",
                                color: "#838383",
                              }}
                            >
                              {Size.price_with_tax} ر.س
                            </p>
                          </>
                        ) : (
                          // Regular Price if no discount
                          <p
                            style={{
                              margin: "0",
                              color: "#838383",
                            }}
                          >
                            {Size.price_with_tax} ر.س
                          </p>
                        )}
                      </div>
                    </div>
                    {Sizes.length - 1 !== index && <hr />}
                  </>
                ))}
              </div>
            </Form>
          </div>
        </div>

        {/* Options of Product Details */}
        {productOptions?.length > 0 && (
          <div className="addon">
            <div className="icon-container">
              <img src={message} alt="message" />
              <h3>الاضافات</h3>
            </div>

            <div className="meal-form">
              <Form style={{ padding: "0" }}>
                <div key={"reverse-checkbox"}>
                  {productOptions?.map((productOption, index) => (
                    <>
                      {" "}
                      <div key={index} className="priceOfOptions">
                        <Form.Check
                          style={{ color: "#838383" }}
                          reverse
                          label={productOption.name}
                          name="group1"
                          type="checkbox"
                          id={`checkbox-${index}`}
                          onChange={
                            (e) =>
                              handleCheckboxChange(
                                e,
                                productOption.id,
                                productOption.price_with_tax
                              ) // Pass price to handler
                          }
                        />
                        <p style={{ color: "#838383" }}>
                          {productOption.price_with_tax} ر.س
                        </p>
                      </div>
                      {productOptions.length - 1 !== index && <hr />}
                    </>
                  ))}
                </div>
              </Form>
            </div>
          </div>
        )}

        {/* Note of Product Details */}
        <Note
          notes={notes}
          setNotes={setNotes}
          text="أضف ملاحظاتك علي الطلب"
          title="أضف ملاحظاتك"
        />
        {/* End Note of Product Details */}
      </div>
    </div>
  );
};

export default ProductDetails;
