import "./cart.css";

function ProductCouponItem({ productDetails, showDivider }) {
  const totalOptionPrice = productDetails.options?.reduce((total, option) => {
    return total + parseFloat(option.price_with_tax);
  }, 0);

  return (
    <>
      <div>
        <div className="productDetails">
          <img
            src={productDetails.product.image}
            alt={productDetails.product.name}
          />
          <div className="product-content">
            <div className="header">
              <h3>{productDetails.product.name}</h3>
            </div>
            <div className="size-details">
              {productDetails.sizes.name}
              {productDetails.options.length > 0
                ? productDetails.options.map((option) => {
                    return (
                      <span key={option.option_id}>
                        {" , "}
                        {option.name}
                      </span>
                    );
                  })
                : " "}
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <div className="price">
                {parseFloat(productDetails.sizes.price_with_tax || 0) +
                  totalOptionPrice}{" "}
                رس
              </div>

              <div
                style={{
                  color: "black",
                  backgroundColor: "rgba(226, 246, 234)",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                X{productDetails.quantity}
              </div>
            </div>
          </div>
        </div>
        {showDivider && <hr />}
      </div>
    </>
  );
}

export default ProductCouponItem;
