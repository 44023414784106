import { useEffect, useState } from "react";
import "./cart.css";
import { Button } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import emptyCart from "../../assets/images/empty-cart.svg";
import { axiosTokenInstance } from "../../axiosConfig/axios";

import cartLocation from "../../assets/images/cart-location.svg";
import arrow from "../../assets/images/arrow.svg";
import ticketDiscount from "../../assets/images/ticket-discount.svg";
import orderImg from "../../assets/images/order-done.svg";
import ProductCouponItem from "./ProductCouponItem";
import { Popup } from "../../components/poupup/Popup";
import Loading from "../../components/loading/loading";
import { Check } from "lucide-react";

const Coupon = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isApplied, setIsApplied] = useState(false);
  const [code, setCode] = useState("");
  const [cartProductDetails, setCartProductDetails] = useState([]);
  const [restaurantDetails, setRestaurantDetails] = useState({});
  let [totalPrice, setTotalPrice] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [promotion_id, setPromotionID] = useState("");
  const [coupon, setCoupon] = useState({});
  const [couponDetails, setCouponDetails] = useState(false);

  // get cart product details
  const getCartDetails = () => {
    axiosTokenInstance.get("user/cart").then((res) => {
      if (res.data.data) {
        setTotalPrice(res.data.data.total_price_with_taxs);
        setCartProductDetails(res.data.data.products);
        setRestaurantDetails(res.data.data.restaurant);
      } else {
        setCartProductDetails([]);
      }

      setIsLoading(false);
    });
  };

  useEffect(() => {
    getCartDetails();
  }, []);

  const restaurant_id = localStorage.getItem("restaurant");
  //apply coupon
  const applyCoupon = () => {
    axiosTokenInstance
      .post(
        `user/order/check-promotion?code=${code}&restaurant_id=${restaurant_id}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
          setIsApplied(true);
          console.log(res.data.data.promotion);

          setPromotionID(res.data.data.promotion.id);
          setCoupon(res.data.data);
          setCouponDetails(true);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      });
  };

  const table_id = localStorage.getItem("table");
  const notes = localStorage.getItem("notes");
  //to make order
  const handleOrderDoneClick = () => {
    axiosTokenInstance
      .post(`user/order/make-order`, { promotion_id, table_id, notes })
      .then((res) => {
        console.log(res.data);
        localStorage.setItem("order-id", res.data.order_id);
        if (res.data.status === 200) {
          setIsPopupOpen(true);
        }
      });
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      {" "}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!isPopupOpen && (
            <div>
              <div style={{ padding: "0 20px" }}>
                <div>
                  <h2
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "black",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    تأكيد الطلب
                  </h2>
                </div>

                {cartProductDetails?.length > 0 ? (
                  <>
                    {/* Start header about restaurant*/}
                    <div className="ordered-from-restaurant">
                      <h3>تم الطلب من</h3>
                      <hr />
                      <div className="content">
                        <div className="details-container">
                          <img src={cartLocation} alt="cart-location" />
                          <div className="details">
                            <h4>{restaurantDetails.name}</h4>
                            <p>{restaurantDetails.address}</p>
                          </div>
                        </div>
                        <Link to="/menu">
                          <img src={arrow} alt="arrow" />
                        </Link>
                      </div>
                    </div>
                    {/* End header about restaurant  */}

                    {/* Start cart products details */}
                    <div className="product-container">
                      <div className="header">
                        <p>تفاصيل الطلب</p>
                      </div>

                      <hr />

                      {cartProductDetails?.map((product, index) => {
                        return (
                          <ProductCouponItem
                            key={product.productCartId}
                            productDetails={product}
                            showDivider={index < cartProductDetails.length - 1}
                          />
                        );
                      })}
                    </div>
                    {/* End cart products details */}

                    {/* Section of Coupon */}
                    <div className="coupon">
                      <img src={ticketDiscount} alt="ticket-discount" />
                      <input
                        placeholder="اضف كود الخصم"
                        type="text"
                        onChange={(e) => setCode(e.target.value)}
                      />
                      <Button onClick={applyCoupon}>
                        {!isApplied ? (
                          "تطبيق"
                        ) : (
                          <>
                            {" "}
                            <Check />
                          </>
                        )}
                      </Button>
                    </div>
                    {/* End Section of Coupon */}

                    {/* Start price details */}

                    <div className="cart-price-details">
                      <h6>تفاصيل الفاتورة</h6>
                      <hr />
                      {couponDetails ? (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              الإجمالي
                            </p>
                            <p style={{ margin: "0" }}>
                              {coupon.cart_total} ر.س
                            </p>
                          </div>
                          <hr />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              الخصم
                            </p>
                            <p style={{ margin: "0" }}>{coupon.discount} ر.س</p>
                          </div>
                          <hr />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                color: "#4FAF5A",
                                fontWeight: "bold",
                              }}
                            >
                              الإجمالي الصافي
                            </p>
                            <p style={{ margin: "0" }}>{coupon.total} ر.س</p>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#4FAF5A",
                          }}
                        >
                          <p
                            style={{
                              margin: "0",

                              fontWeight: "bold",
                            }}
                          >
                            الإجمالي الصافي
                          </p>

                          <p style={{ margin: "0" }}>{totalPrice}</p>
                        </div>
                      )}
                    </div>

                    {/* End  price details */}
                  </>
                ) : (
                  <></>
                )}

                <ToastContainer />
              </div>

              {cartProductDetails?.length > 0 && (
                <div className="main-content-price-cart">
                  <div className="cart-group-btns">
                    <Button
                      style={{
                        backgroundColor: "#37BD6B",
                        color: "white",
                        border: "none",
                        width: "100%",
                      }}
                      onClick={handleOrderDoneClick}
                    >
                      تأكيد الطلب
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Popup */}
          {isPopupOpen && (
            <Popup
              imgPoupoup={orderImg}
              title="تم تأكيد طلبك بنجاح"
              closePopup={closePopup}
              textBtn="الذهاب الى طلباتى"
              href="/order"
            />
          )}
        </>
      )}
    </>
  );
};

export default Coupon;
