import { useNavigate } from "react-router-dom";
import walletMoney from "../../assets/images/wallet-money.svg";
import { axiosTokenInstance } from "../../axiosConfig/axios";
import "./payment.css";

const WaysOfPayment = () => {
  const navigate = useNavigate();
  const order_id = localStorage.getItem("order-id");

  const payment = async () => {
    try {
      const { data } = await axiosTokenInstance.post(
        `user/order/fullPaid/${order_id}`,
        { payment_from: "web-view" }
      );

      if (data?.redirect_url) {
        window.location.href = data.redirect_url;
      } else {
        console.error("Redirect URL not found!");
      }
    } catch (error) {
      console.error("Payment error:", error);
    }
  };

  return (
    <div className="payment-methods-container">
      <h3 className="payment-header">طريقة الدفع</h3>
      <div className="payment-method">
        <div className="payment-option" onClick={payment}>
          <img src={walletMoney} alt="wallet-icon" />
          <p className="payment-text">دفع الطلب كامل</p>
        </div>
        <div
          className="payment-option"
          onClick={() => navigate("/split-order")}
        >
          <img src={walletMoney} alt="wallet-icon" />
          <p className="payment-text">دفع جزء من الطلب</p>
        </div>
      </div>
    </div>
  );
};

export default WaysOfPayment;
