import { useState } from "react";
import "./review.css";
import emotionIMg1 from "../../assets/images/emotions/emotion1.svg";
import emotionIMg2 from "../../assets/images/emotions/emotion2.svg";
import emotionIMg3 from "../../assets/images/emotions/emotion3.svg";
import emotionIMg4 from "../../assets/images/emotions/emotion4.svg";
import emotionIMg5 from "../../assets/images/emotions/emotion5.svg";
import { axiosTokenInstance } from "../../axiosConfig/axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Review = () => {
  const navigate = useNavigate();
  const [rating, setRating] = useState(1);
  const [review, setReview] = useState("");
  const [emotionIMg, setEmotionImg] = useState(emotionIMg1);

  const handleRatingClick = (star) => {
    setRating(star);

    // Set the appropriate image based on the star rating
    switch (star) {
      case 1:
        setEmotionImg(emotionIMg1);
        break;
      case 2:
        setEmotionImg(emotionIMg2);
        break;
      case 3:
        setEmotionImg(emotionIMg3);
        break;
      case 4:
        setEmotionImg(emotionIMg4);
        break;
      case 5:
        setEmotionImg(emotionIMg5);
        break;
      default:
        setEmotionImg(emotionIMg1);
    }
  };

  const order_id = localStorage.getItem("order-id");
  const restaurant_id = localStorage.getItem("restaurant");
  const makeReview = async () => {
    const { data } = await axiosTokenInstance.post(
      `user/reviews/${restaurant_id}`,
      {
        order_id,
        rate: rating,
        review,
      }
    );

    if (data?.status === 200) {
      navigate("/thanksOnReview");
    } else {
      toast.error(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleSubmit = () => {
    makeReview();
  };

  return (
    <div className="review-container">
      {/* <div className="review-header">
        <h2>مطعم الطازج</h2>
      </div> */}
      <img src={emotionIMg} alt="emotion-review" />
      <h3>"كيف كانت تجربتك في مطعمنا؟"</h3>

      <div className="stars-container">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`star ${rating >= star ? "filled" : ""}`}
            onClick={() => handleRatingClick(star)}
          >
            ★
          </span>
        ))}
      </div>

      <textarea
        className="review-textarea"
        placeholder="يهمنا رأيك"
        value={review}
        onChange={(e) => setReview(e.target.value)}
      ></textarea>

      <button className="submit-button" onClick={handleSubmit}>
        ارسل تقييمك
      </button>
      <ToastContainer />
    </div>
  );
};

export default Review;
